/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
import { polygonContainsPoint as t, extentContainsPoint as e, ringsContainsCoords as o } from "../../../geometry/support/contains.js";
const r = t => parseFloat(t) / 100;
function n(t, e, o) {
  const n = "string" == typeof t.left ? r(t.left) * e : t.left,
    m = "string" == typeof t.right ? r(t.right) * e : t.right,
    y = "string" == typeof t.top ? r(t.top) * o : t.top,
    a = "string" == typeof t.bottom ? r(t.bottom) * o : t.bottom,
    i = y;
  return {
    xmin: n,
    xmax: Math.max(e - m, n),
    ymin: i,
    ymax: Math.max(o - a, y)
  };
}
function m(r, m, y, a) {
  switch (m.type) {
    case "rect":
      {
        const {
            width: t,
            height: e
          } = r,
          {
            xmin: o,
            xmax: a,
            ymin: i,
            ymax: p
          } = n(m, t, e),
          {
            x: g,
            y: s
          } = y;
        return g > o && g < a && s > i && s < p;
      }
    case "path":
      return 0 === m.path.length || !Array.isArray(m.path[0][0]) || o(m.path, [y.x, y.y]);
    case "geometry":
      return null == m.geometry || ("polygon" === m.geometry.type ? t(m.geometry, a) : e(m.geometry, a));
  }
}
export { m as clipContainsPoint, n as createScreenExtent };